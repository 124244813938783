import { FC, ReactNode } from "react";

type Props = {
  label: string;
  content: ReactNode;
};

export const CreditItem: FC<Props> = ({ label, content }): JSX.Element => {
  return (
    <dl className="flex items-start text-xs justify-between py-8 space-x-24">
      <dt className="text-secondary flex-shrink-0">{label}</dt>
      <dd>{content}</dd>
    </dl>
  );
};
