import client from "@/utils/api/client";
import useFetch from "@/hooks/useFetch";
import { ProjectDetailResponse } from "@/web-client/api";

interface ProjectHookResponse {
  project?: ProjectDetailResponse;
  setProject(project?: ProjectDetailResponse, shouldRevalidate?: boolean);
  error?: Error;
}

const useProject = (
  id: number,
  initialProject?: ProjectDetailResponse,
): ProjectHookResponse => {
  const {
    data: project,
    error,
    mutate,
  } = useFetch<ProjectDetailResponse>(
    `/projects/${id}`,
    async () => {
      const { data } = await client.projectsIdGet({ id });

      return data;
    },
    initialProject,
    { waitForAuth: true },
  );

  return { project, error, setProject: mutate };
};

export default useProject;
