import { ProjectConstructionType } from "@/web-client/api";

const formatConstructionType = (value: ProjectConstructionType): string => {
  switch (value) {
    case ProjectConstructionType.NEW:
      return "新築";

    case ProjectConstructionType.RENOVATION:
      return "リノベーション";
  }
};
export default formatConstructionType;
