import { FC, CSSProperties } from "react";
import IconLikeFill from "../../../assets/imgs/svg/icon-like-fill.svg";
import { theme } from "tailwind.config";
import clsx from "clsx";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";

type Props = {
  hasLiked: boolean;
  onClick: VoidFunction;
  likesCount?: number;
  style?: CSSProperties;
  hideCount?: boolean;
  hideLabel?: boolean;
};

const LikeBtn: FC<Props> = ({
  hasLiked,
  onClick,
  likesCount,
  style,
  hideCount = false,
  hideLabel = false,
}): JSX.Element => {
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    reason: RedirectReason.Like,
    action: onClick,
  });

  return (
    <>
      <button
        style={style}
        className={clsx(
          "relative rounded-full px-16 py-4 overflow-hidden truncate border border-primary",
          hasLiked ? "bg-like text-white" : "text-gray-400 bg-white",
          "hover:brightness-110",
        )}
        onClick={executeAction}
      >
        <span className="flex gap-8 justify-between items-center text-sm">
          <span className="flex items-center gap-4">
            <IconLikeFill
              width={16}
              height={16}
              fill={hasLiked ? theme.colors.white : theme.colors.gray[300]}
            />

            {!hideLabel && <span>{hasLiked ? "LIKED" : "LIKE"}</span>}
          </span>
          {!hideCount && <span>{likesCount}</span>}
        </span>
      </button>
      {SignInModalComponent}
    </>
  );
};

export default LikeBtn;
