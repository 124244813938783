import { useCallback } from "react";
import useUserStatus from "@/features/user/hooks/useUserStatus";
import useModalLimitFunction from "./useModalLimitFunction";

interface UseBtnRequiresIsDesignerReturn {
  clickHandler: () => void;
}

const useBtnRequiresIsDesigner = (
  onAuthenticated: () => void,
): UseBtnRequiresIsDesignerReturn => {
  const { isDesigner } = useUserStatus();
  const { open } = useModalLimitFunction();

  const clickHandler = useCallback(() => {
    if (!isDesigner) {
      open();

      return;
    }

    onAuthenticated();
  }, [onAuthenticated, isDesigner, open]);

  return {
    clickHandler,
  };
};
export default useBtnRequiresIsDesigner;
