import { useEffect, useMemo, useState } from "react";

type UseClampItemsResponse<T> = {
  items: T[];
  open: VoidFunction;
  close: VoidFunction;
  isEnable: boolean;
};

const useClampItems = <T>(
  items: T[],
  min: number,
  defaultEnable: boolean,
): UseClampItemsResponse<T> => {
  const [enable, setEnable] = useState(defaultEnable);
  const displayItems = useMemo(() => {
    if (!enable) return items;

    return items.slice(0, min);
  }, [items, enable, min]);

  useEffect(() => {
    setEnable(defaultEnable);
  }, [defaultEnable]);

  return {
    items: displayItems,
    open: () => setEnable(false),
    close: () => setEnable(true),
    isEnable: enable,
  };
  displayItems;
};
export default useClampItems;
