import { FC, PropsWithChildren } from "react";

const ChildrenWithSeparate: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  return (
    <div className="relative flex items-center justify-center w-full space-x-8">
      <div className="w-full border-b border-primary" />
      <div className="flex-shrink-0 relative flex items-center">{children}</div>
      <div className="w-full border-b border-primary" />
    </div>
  );
};
export default ChildrenWithSeparate;
