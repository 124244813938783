import { ProjectAttachmentType } from "@/web-client";

export const formatAttachmentType = (
  attachmentType: ProjectAttachmentType,
): string => {
  switch (attachmentType) {
    case ProjectAttachmentType.CONCEPT_IMAGE:
      return "コンセプトイメージ";

    case ProjectAttachmentType.DIAGRAM:
      return "ダイアグラム";

    case ProjectAttachmentType.DRAWING:
      return "図面";

    case ProjectAttachmentType.MODEL_PHOTO:
      return "模型写真";

    case ProjectAttachmentType.RENDERING:
      return "レンダリング";

    case ProjectAttachmentType.OTHER:
      return "その他";
  }
};
