import IconButton from "@/components/IconButton";
import { formatAttachmentType } from "@/features/project_attachment/utils";
import { ProjectAttachment } from "@/web-client";
import Image from "next/image";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactModal from "react-modal";
import IconClose from "../../../../assets/imgs/svg/icon-close.svg";
import IconArrowRight from "../../../../assets/imgs/svg/icon-arrow-right.svg";
import IconArrowLeft from "../../../../assets/imgs/svg/icon-arrow-left.svg";
import useDevice from "@/hooks/useDevice";
import clsx from "clsx";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperCore, { Keyboard } from "swiper";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  index: number;
  attachments: ProjectAttachment[];
}

const DialogViewProjectAttachment: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  attachments,
  index,
}): JSX.Element => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const modalStyle: ReactModal.Styles = useMemo(() => {
    return {
      content: {
        inset: "unset",
        position: "relative",
        overflow: "scroll",
        height: "100%",
        width: "100%",
        padding: 0,
        background: "none",
        border: "none",
        display: "grid",
      },
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 9999,
        display: "grid",
        placeItems: "center",
        placeSelf: "center",
      },
    };
  }, []);

  const [currentIndex, setCurrentIndex] = useState<number>(index);

  const slideChangeHandler = useCallback((swiper: SwiperCore) => {
    setCurrentIndex(swiper.realIndex);
  }, []);

  const currentAttachment = useMemo(() => {
    return attachments[currentIndex];
  }, [currentIndex, attachments]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(index, 0);
    }
  }, [swiper, index]);

  return (
    <ReactModal
      style={{ content: modalStyle.content, overlay: modalStyle.overlay }}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div className="grid grid-rows-[1fr_78px]">
        <div className="relative grid">
          <Swiper
            spaceBetween={32}
            slidesPerView={1}
            onSlideChange={slideChangeHandler}
            className="w-full h-full"
            keyboard={{
              enabled: true,
            }}
            modules={[Keyboard]}
            onSwiper={setSwiper}
            loop
          >
            {attachments.map((attachment) => (
              <SwiperSlide
                key={attachment.id}
                className="relative p-24 [&>*]:absolute [&>*]:inset-0 [&>*]:w-full [&>*]:h-full"
              >
                <Image
                  className="object-contain object-center laptop:p-[72px]"
                  src={attachment.upload_image.urls.original}
                  alt={attachment.title}
                  fill
                  unoptimized
                />
              </SwiperSlide>
            ))}

            {attachments.length > 1 && (
              <>
                <PrevButton />
                <NextButton />
              </>
            )}
          </Swiper>
        </div>
        {currentAttachment && (
          <footer className="pointer-events-auto absolute inset-x-0 bottom-0 bg-white p-16 grid gap-4 text-primary">
            <p className="font-bold text-base">{currentAttachment.title}</p>
            <small className="text-xs">
              {formatAttachmentType(currentAttachment.attachment_type)}
            </small>
          </footer>
        )}
      </div>

      <div className="absolute top-[16px] right-[16px] z-50">
        <IconButton
          className="pointer-events-auto"
          onClick={onClose}
          aria-label="補足資料を閉じる"
          variant="secondary"
          size="large"
          rounded
        >
          <IconClose />
        </IconButton>
      </div>
    </ReactModal>
  );
};

export default DialogViewProjectAttachment;

const NextButton: FC = () => {
  const { isPc } = useDevice();
  const swiper = useSwiper();

  return (
    <IconButton
      className={clsx(
        "absolute top-1/2 right-0 transform -translate-y-1/2",
        "-translate-x-[8px]",
        "laptop:-translate-x-[16px]",
        "z-50",
      )}
      size={isPc ? "large" : "small"}
      onClick={() => swiper.slideNext()}
      rounded
      aria-label="次の補足資料へ"
    >
      <IconArrowRight />
    </IconButton>
  );
};

const PrevButton: FC = () => {
  const { isPc } = useDevice();
  const swiper = useSwiper();

  return (
    <IconButton
      className={clsx(
        "absolute top-1/2 left-0 transform -translate-y-1/2",
        "translate-x-[8px]",
        "laptop:translate-x-[16px]",
        "z-50",
      )}
      size={isPc ? "large" : "small"}
      onClick={() => swiper.slidePrev()}
      rounded
      aria-label="前の補足資料へ"
    >
      <IconArrowLeft />
    </IconButton>
  );
};
