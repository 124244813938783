import useDevice from "@/hooks/useDevice";
import { FC, PropsWithChildren } from "react";
import FlexibleGrid from "@/components/FlexibleGrid";

const ProjectItemList: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  const { isSp } = useDevice();

  return (
    <FlexibleGrid rowGap={16} columnGap={16} minWidth={isSp ? 240 : 400}>
      {children}
    </FlexibleGrid>
  );
};

export default ProjectItemList;
