import DialogViewProjectAttachment from "@/features/project_attachment/components/DialogViewProjectAttachment";
import { formatAttachmentType } from "@/features/project_attachment/utils";
import useDisclosure from "@/hooks/useDisclosure";
import { ProjectAttachment } from "@/web-client";
import clsx from "clsx";
import Image from "next/image";
import { FC, useMemo } from "react";

interface Props {
  attachments: ProjectAttachment[];
  index: number;
}

const ProjectAttachmentItem: FC<Props> = ({ attachments, index }) => {
  const { isOpen, openHandler, closeHandler } = useDisclosure();
  const attachment = useMemo(() => attachments[index], [index, attachments]);

  return (
    <>
      <div className={clsx("relative aspect-square overflow-hidden", "group")}>
        <Image
          className="object-cover"
          src={attachment.upload_image.urls.small}
          alt={attachment.title}
          fill
          unoptimized
        />

        <header className="relative p-8">
          <small
            className={clsx(
              "relative inline-block",
              "bg-overlay px-12 py-8 rounded-full text-white text-xs opacity-0 transition-all",
              "group-hover:opacity-100",
              "truncate",
            )}
          >
            {formatAttachmentType(attachment.attachment_type)}
          </small>
        </header>

        <button
          onClick={openHandler}
          aria-label="補足情報を拡大する"
          className="absolute inset-0"
        />
      </div>

      <DialogViewProjectAttachment
        isOpen={isOpen}
        onClose={closeHandler}
        attachments={attachments}
        index={index}
      />
    </>
  );
};
export default ProjectAttachmentItem;
