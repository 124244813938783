import { FC, ReactNode } from "react";

type Props = {
  label: ReactNode;
  frontIcon?: JSX.Element;
  backIcon?: JSX.Element;
};

const OldIconButton: FC<Props> = ({
  label,
  frontIcon,
  backIcon,
}): JSX.Element => {
  return (
    <span className="grid grid-cols-[16px_minmax(0,_1fr)_16px] gap-8 items-center border border-primary rounded-full py-8 px-16 hover:bg-gray-100 transition-all bg-white">
      <span>{frontIcon}</span>
      <span className="text-center text-xs text-primary truncate leading-none">
        {label}
      </span>
      <span>{backIcon}</span>
    </span>
  );
};
export default OldIconButton;
