import { FC, PropsWithChildren } from "react";

const SimpleIconButton: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  return (
    <span
      className={[
        "p-8 flex justify-center items-center rounded-full bg-white border border-primary",
        "hover:bg-gray-100 transition-all",
      ].join(" ")}
    >
      {children}
    </span>
  );
};
export default SimpleIconButton;
